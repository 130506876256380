footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../assets/bg_wool_dark.png");
  width: 100%;
}
footer div.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
  width: 90%;
  max-width: 1280px;
  box-sizing: border-box;
  color: #ffffff;
}
@media screen and (max-width: 1024px) {
  footer div.wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;
  }
}
@media screen and (max-width: 640px) {
  footer div.wrapper {
    align-items: center;
  }
}
@media screen and (max-width: 640px) {
  footer div.wrapper nav ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
  }
}
footer .medias {
  display: flex;
  gap: 1.6rem;
}