.special-point {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.special-point__heading {
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 100%;
  color: #868686;
}
.special-point__heading svg {
  height: 2.4rem;
  width: auto;
  fill: #868686;
}