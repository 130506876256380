.update_avatar {
  display: flex;
  flex-direction: column;
  width: 18rem;
}
.update_avatar--image {
  position: relative;
  cursor: pointer;
  border-radius: 100%;
  border: solid transparent;
  overflow: hidden;
  width: 18rem;
  height: 18rem;
}
.update_avatar--image img {
  width: 100%;
  height: 100%;
  line-height: 18rem;
  display: flex;
  text-align: center;
}
.update_avatar--image img:hover {
  opacity: 50%;
}
.update_avatar form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.update_avatar form button {
  margin: 1rem 0;
  color: #043f7d;
  cursor: pointer;
}