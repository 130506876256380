.header .account {
  position: relative;
  display: flex;
}
.header .account__dropdown-btn {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;
  color: inherit;
  text-transform: uppercase;
}
.header .account__icon {
  font-size: 1.2em;
  fill: inherit;
}
.header .account__dropdown {
  position: absolute;
  top: calc(100% + 1rem);
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  background-color: #181818;
}
.header .account__dropdown * {
  box-sizing: border-box;
  padding: 1rem;
  text-align: center;
  color: inherit;
}
.header .account__dropdown *:hover {
  background-color: #e2e1e1;
  color: black;
}
.header .account__dropdown-close {
  display: none;
}
.header .account__dropdown-open {
  display: flex;
}