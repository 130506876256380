@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");
.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background: url(./assets/bg_wool_light.png);
  color: #191919;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.6rem;
}
.App h1 {
  font-size: 5rem;
  font-weight: 700;
}
@media screen and (max-width: 640px) {
  .App h1 {
    font-size: 3.4rem;
    font-weight: 550;
  }
}
.App h2 {
  font-size: 2.8rem;
  font-weight: 550;
}
@media screen and (max-width: 1024px) {
  .App h2 {
    font-size: 2.4rem;
  }
}
.App h3 {
  font-size: 2.4rem;
  font-weight: 550;
  line-height: 1.6;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: #003169;
}
.App h4 {
  font-size: 1.6rem;
  font-weight: 550;
  line-height: 2;
  text-transform: uppercase;
}
.App p,
.App span {
  font-size: 1.6rem;
  line-height: 1.8;
}
.App a {
  color: #4188ff;
  text-decoration: none;
}