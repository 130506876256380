.about_us--get_in_touch {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10rem 0;
}
.about_us--get_in_touch--content {
  width: 90%;
  max-width: 1280px;
}
.about_us--get_in_touch--heading {
  padding-bottom: 2rem;
  font-size: 2.8rem;
  font-weight: bold;
  border-bottom: 2px solid grey;
}
.about_us--get_in_touch--detail {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  height: 20rem;
}
.about_us--get_in_touch--detail img {
  max-width: 10rem;
  min-height: 10rem;
  object-fit: cover;
}