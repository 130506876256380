.intro-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .intro-logo {
    margin-top: 2rem;
  }
}