.list-table {
  border: 2px solid #01307c;
  width: 100%;
  text-align: left;
  white-space: nowrap;
}
.list-table tr td,
.list-table tr th {
  padding: 1.6rem;
  border-bottom: 1px solid #01307c;
}
.list-table__head {
  background: #f8faff;
  text-align: left;
  font-weight: bold;
  text-transform: capitalize;
}
.list-table__head tr th {
  position: relative;
}
.list-table__head tr th:not(:last-child):after {
  position: absolute;
  right: 0;
  box-sizing: border-box;
  width: 1px;
  height: 1.6em;
  background-color: #01307c;
  content: "";
}
.list-table__body tr {
  box-sizing: border-box;
  border-bottom: 1px solid #01307c;
  background: #ffffff;
  font-size: 1.4rem;
  transition: 0.3s;
}
.list-table__body tr:hover {
  background: #d1e3ff;
}
.list-table__foot {
  font-size: 1.2rem;
  text-align: center;
}
.list-table__colgroup .align-left {
  width: 20px;
}
.list-table__colgroup .align-center {
  text-align: center;
}
.list-table__colgroup .align-right {
  text-align: right;
}