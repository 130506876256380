.form--button-secondary {
  padding: 0.5rem;
  background: #e0e0e0;
  border: 2px solid #000000;
  color: #292929;
  font-weight: bold;
  letter-spacing: 3px;
  text-transform: uppercase;
  transition: 0.3s;
}
.form--button-secondary:not(:disabled):hover {
  background: #f5d625;
  transform: translateY(-0.1rem);
}
.form--button-secondary:active {
  transform: translateY(0);
}
.form--button-secondary:disabled {
  border: 2px solid #3a3838;
  background: #67788b;
  color: #e0e0e0;
}