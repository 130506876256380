.profile--posts--items {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  margin-top: -2.5rem;
}
.profile--posts--items--item {
  padding-top: 5rem;
  border-top: solid 1px lightgray;
}
.profile--posts--items--item:first-child {
  border: none;
  padding: 0;
}
.profile--posts--items--item--header {
  padding-bottom: 1.6rem;
}
.profile--posts--items--item--header h3 {
  font-size: 2.4rem;
  text-transform: uppercase;
  color: #003169;
  margin-bottom: 0.5rem;
}
.profile--posts--items--item--header p {
  color: #868686;
}