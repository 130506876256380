.text_field {
  width: 100%;
}
.text_field--label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1.8rem;
  color: #003169;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}
.text_field--label svg {
  color: #003169;
}
.text_field--form {
  margin-top: 1rem;
  width: 100%;
  padding: 1rem 0;
  font-size: 1.6rem;
  border: 1px solid lightgray;
  transition: 0.3s;
}
.text_field--form:focus {
  outline: 0;
  box-shadow: inset 0px -4px 0px #000d23;
}