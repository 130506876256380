.two-col {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10rem 0;
}
.two-col__wrapper {
  width: 90%;
  max-width: 1280px;
}
.two-col__wrapper__body {
  display: flex;
  flex-direction: row;
  gap: 8rem;
}
@media screen and (max-width: 640px) {
  .two-col__wrapper__body {
    flex-direction: column;
    gap: 0;
  }
}
.two-col__wrapper__body__left {
  width: 50%;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 640px) {
  .two-col__wrapper__body__left {
    width: 100%;
  }
}
.two-col__wrapper__body__right {
  width: 50%;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 640px) {
  .two-col__wrapper__body__right {
    width: 100%;
  }
}