.post-item {
  box-sizing: border-box;
  border-bottom: 1px solid #d6d6d6;
  background: #ffffff;
  font-size: 1.4rem;
  transition: 0.3s;
}
.post-item:hover {
  border-bottom: 1px solid #bbbbbb;
  background: #d6d6d6;
}
.post-item__text--level-small {
  font-size: 1.4rem;
  color: grey;
}
.post-item__text--level-large {
  font-size: 1.6rem;
}
.post-item__cell--meta {
  text-align: center;
}