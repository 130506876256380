.save_changes {
  width: 100%;
  padding: 1.2rem;
  background: #043f7d;
  border: 2px solid #000000;
  color: #ffffff;
  font-weight: bold;
  letter-spacing: 3px;
  text-transform: uppercase;
  transition: 0.3s;
  cursor: pointer;
}
.save_changes:not(:disabled):hover {
  background: #0464c9;
  transform: translateY(-0.1rem);
}
.save_changes:active {
  transform: translateY(0);
}
.save_changes:disabled {
  border: 2px solid #3a3838;
  background: #67788b;
}