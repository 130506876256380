.channel {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.channel__title {
  font-size: 2.8rem;
  padding: 2rem 0;
  font-weight: bold;
}
.channel__content {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  gap: 1rem;
  margin: 0 20vw;
  padding: 2rem;
  background: white;
}