.profile_edit--pannel {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  align-items: center;
}
.profile_edit--pannel--row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 640px;
}
@media screen and (max-width: 640px) {
  .profile_edit--pannel--row {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}
.profile_edit--pannel--row--show {
  padding-bottom: 1.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
@media screen and (max-width: 640px) {
  .profile_edit--pannel--row--show {
    flex-direction: column;
    align-items: center;
  }
}
.profile_edit--pannel--row--show--username {
  font-size: 2.8rem;
  color: #003169;
  font-weight: bold;
}
.profile_edit--pannel--row--show--email, .profile_edit--pannel--row--show--created {
  color: #191919;
}
.profile_edit--pannel--column {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.profile_edit--pannel--save {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}