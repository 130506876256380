.page--banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  background-position: center;
  background-size: cover;
}
.page--banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./assets/overlay-video.7139fa4a.png");
}
.page--banner h1 {
  color: #ffffff;
  z-index: 10;
}