html {
  font-size: 62.5%;
}

/* prettier-ignore */
body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: baseline;
}

button {
  outline: none;
  border: none;
  padding: 0;
  background: none;

  font: inherit;
  font-size: 1.6rem;
}

table {
  border-spacing: 0;
}

/* prettier-ignore */
ol, ul {
  list-style: none;
}
