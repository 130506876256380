.about_us--photo_album {
  background-color: #003169;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10rem 0;
}
.about_us--photo_album--wrapper {
  width: 90%;
  max-width: 1280px;
}
.about_us--photo_album--wrapper--body {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 3.2rem;
  justify-content: space-between;
}
@media screen and (max-width: 640px) {
  .about_us--photo_album--wrapper--body {
    flex-direction: column;
  }
}
.about_us--photo_album--wrapper--body--card img {
  width: 100%;
}
.about_us--photo_album--wrapper--body--card span {
  background-color: #ffffff;
  display: block;
  padding: 1rem 2rem;
  text-align: center;
}