.container {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 50px;
  margin-top: 50px;
}
.container .__content-container {
  background-color: #f6f7f8;
  min-height: 200px;
  width: 90%;
  font-size: 15px;
}
.container .comment-container {
  margin-left: 50px;
  -webkit-box-orient: vertical;
}

.channel__title {
  font-size: 2.8rem;
  padding: 2rem 0;
  font-weight: bold;
}

.channel__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  gap: 1rem;
  margin: 0 20vw;
  padding: 2rem;
  background: white;
}