.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 1em 10vw;
  position: relative;
  background: url("../../assets/bg_wool_dark.png");
  color: #ffffff;
  fill: #ffffff;
  font-size: 1.6rem;
  z-index: 150;
}
.header__nav {
  display: flex;
  align-items: center;
  gap: 6rem;
}
@media screen and (max-width: 1024px) {
  .header__nav {
    display: none;
  }
}
.header__nav ul {
  display: flex;
  gap: 6rem;
  align-items: center;
}
.header__nav ul a {
  color: inherit;
}
.header__nav ul .active {
  border-bottom: 1px solid;
  border-color: inherit;
}
.header .menuBar {
  display: none;
  color: #ffffff;
}
@media screen and (max-width: 1024px) {
  .header .menuBar {
    display: block;
    cursor: pointer;
  }
}
.header__nav__mobile {
  display: none;
}
.header__nav__mobile svg {
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .header__nav__mobile {
    position: absolute;
    left: 0;
    top: 100%;
    background: url("../../assets/bg_wool_dark.png");
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
  }
  .header__nav__mobile__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
    padding: 1.6rem 0;
  }
  .header__nav__mobile__wrapper ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .header__nav__mobile__wrapper ul a {
    color: #ffffff;
  }
  .header__nav__mobile__wrapper__auth {
    gap: 1.6rem;
  }
}
.header .active {
  opacity: 1;
  visibility: visible;
}
.header .inactive {
  opacity: 0;
  visibility: hidden;
}