.header .brand {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  fill: inherit;
  color: inherit;
}
.header .brand__logo {
  font-size: 1.5em;
}