.menu ul {
  display: flex;
  gap: 1.6rem;
  list-style: none;
}
.menu ul li a {
  color: inherit;
}
.menu ul li a:hover {
  border-bottom: 1px solid #dddddd;
}