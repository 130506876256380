.forum-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.forum-page .page__content {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  gap: 1rem;
  margin: 0 20vw;
  padding: 2rem;
  background: white;
}
.forum-page .tab-menu {
  display: flex;
  gap: 0.5rem;
  width: 100%;
}
.forum-page .tab-menu__item {
  position: relative;
  border-radius: 2px 2px 0px 0px;
  padding: 0.5rem 2rem;
  color: #000;
}
.forum-page .tab-menu__item:hover {
  background-color: #1657be;
  color: #fff;
}
.forum-page .tab-menu .active {
  background-color: #01307c;
  color: #fff;
}

.hidden {
  display: none;
}