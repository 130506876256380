body {
  --default-link-color: rgb(61, 61, 61);
  --primary-high-color: rgb(247, 66, 66);
  --primary-focus-color: #4078c0;
}

.post-editor {
  justify-self: center;
  position: fixed;
  display: inline-block;
  flex-direction: column;
  justify-content: stretch;
  bottom: 0;
  margin: 5px auto;
  max-width: 1475px;
  /* KEY value */
  z-index: 400;
  transition: height 250ms ease, background 250ms ease, transform 250ms ease, max-width 250ms ease, padding-bottom 250ms ease;
  background-color: white;
  box-shadow: 0 -1px 40px rgba(0, 0, 0, 0.12);
}
.post-editor--hidden {
  position: fixed;
  display: flex;
  min-height: 5px;
  bottom: 0;
  box-shadow: 0 -1px 40px rgba(0, 0, 0, 0.12);
  transition: 20ms;
}
.post-editor .boxResumer--hidden {
  display: none;
  transition: 20ms;
}

.gripple {
  background-color: #383535;
  cursor: row-resize;
  padding: 3px 0;
  display: block;
}

.gripple > div {
  width: 2%;
  border-top: 1px solid lightgrey;
  padding-top: 1px;
  margin: 0 auto;
}

.box-area {
  display: flex;
  gap: 15px;
  padding: 5px 15px;
  box-sizing: border-box;
}
.box-area--hidden {
  display: none;
  transition: 20ms;
}
.box-area .form-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 5px;
}
.box-area .form-area .quill__wrapper {
  display: flex;
  font-family: Helvetica, Arial, sans-serif;
  height: var(--form-area-height);
  align-self: stretch;
}
.box-area .form-area .quill__wrapper .quill-editor {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  overflow-wrap: break-word;
  overflow-y: hidden;
}
.box-area .form-area .control-area {
  display: flex;
  gap: 10px;
  padding: 5px 0;
}
.box-area .form-area .control-area .form--button-submit {
  margin: 0;
  padding: 0 8px;
}
.box-area .form-area .control-area .button-label {
  margin-left: 6px;
}
.box-area .form-area .control-area .togglePreview {
  float: right;
}
.box-area .preview-area {
  flex: 1;
  display: flex;
  text-align: left;
  outline: 1px dashed #b4b4b4;
}
.box-area .preview-area--hidden {
  display: none;
  transition: 20ms;
}
.box-area .preview-area .preview-box {
  height: calc(var(--form-area-height) + 95px);
  /* width: 100px; */
  overflow-y: scroll;
  display: block;
  flex-grow: 1;
  font-family: Helvetica, Arial, sans-serif;
  padding: 5px;
}

.btn {
  position: relative;
  display: block;
  padding: 6px 12px;
  font-weight: 600;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid;
  border-radius: 0.25em;
}

.btn-primary {
  color: #fff;
  background-color: #28a745;
  background-image: linear-gradient(-180deg, #34d058 0%, #28a745 90%);
}

.btn-icon {
  margin-right: 6px;
}

a {
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  color: var(--default-link-color);
}

a.cancel {
  position: relative;
  display: block;
  margin-left: 1.25em;
  line-height: normal;
}

a.cancel:hover {
  color: var(--primary-high-color);
  transition: color 250ms;
}