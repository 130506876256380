.banner {
  position: relative;
  display: flex;
  justify-content: center;
  height: 80vh;
  align-items: center;
  background-image: url("./assets/bannerbg.png");
  background-position: center;
  background-size: cover;
  overflow: hidden;
}
.banner .video {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
.banner:after {
  content: "";
  position: absolute;
  z-index: 120;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("./assets/overlay-video.7139fa4a.png");
}
.banner .welcome {
  z-index: 200;
  position: absolute;
  bottom: 0;
  background-color: #191919;
  opacity: 80%;
  padding: 2rem 0;
  width: 100%;
  color: #ffffff;
  text-align: center;
}
.banner .welcome h1 {
  color: #ffffff;
}