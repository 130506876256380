.profile {
  margin: 0 10vw;
  padding: 10rem;
  background-color: white;
}
@media screen and (max-width: 1024px) {
  .profile {
    margin: 0;
    padding: 10rem 2rem;
  }
}