.terms_content {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 2vw 10vw;
  justify-content: center;
}
.terms_content--title {
  align-items: center;
  padding: 0.5rem 0;
  font-size: 2.8rem;
  font-weight: bold;
  border-bottom: 2px solid grey;
}
.terms_content--title .terms_content--warning {
  display: block;
  padding-top: 0.5rem;
  font-size: 1.9rem;
  font-weight: bold;
}
.terms_content--content {
  text-align: justify;
  font-size: 1.7rem;
  justify-content: space-between;
  list-style-type: numbers;
  padding-left: 2rem;
  padding-top: 1rem;
  line-height: 3rem;
}