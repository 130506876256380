.join-discord {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 640px) {
  .join-discord {
    margin-top: 2rem;
  }
}
.join-discord button {
  margin-top: 1.6rem;
  padding: 1rem 2rem;
  max-width: fit-content;
  background-color: #003169;
  border-radius: 8px;
  cursor: pointer;
}
.join-discord button a {
  color: #ffffff;
}