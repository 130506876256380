.homepage--current_event {
  display: flex;
  justify-content: center;
  margin: 4.8rem 0;
}
.homepage--current_event .slick-slider {
  position: relative;
  display: flex;
  justify-content: center;
  width: 90%;
  max-width: 1280px;
}
.homepage--current_event .slick-slider .slick-prev,
.homepage--current_event .slick-slider .slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}
.homepage--current_event .slick-slider .slick-prev {
  left: 0;
}
.homepage--current_event .slick-slider .slick-next {
  right: 0;
}
.homepage--current_event .slick-slider .slick-list {
  height: 100%;
  width: 95%;
}
.homepage--current_event .slick-slider .slick-list .slick-track {
  height: 100%;
  display: flex;
  gap: 20px;
}
.homepage--current_event .slick-slider .slick-list .slick-track .slick-slide {
  height: 100%;
}
.homepage--current_event .slick-slider .slick-list .slick-track .slick-slide .myDiv {
  height: 100%;
  position: relative;
}
.homepage--current_event .slick-slider .slick-list .slick-track .slick-slide .myDiv::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(16, 11, 117, 0.6713060224) 0%, rgba(9, 9, 121, 0.3519782913) 55%, rgba(181, 181, 255, 0.1446953782) 100%);
  opacity: 0.5;
}
.homepage--current_event .slick-slider .slick-list .slick-track .slick-slide .myDiv img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.homepage--current_event .slick-slider .slick-list .slick-track .slick-slide .myDiv div {
  position: absolute;
  bottom: 10%;
  left: 10%;
  color: white;
}