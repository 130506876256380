.profile--user {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 640px) {
  .profile--user {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}
.profile--user--info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 640px) {
  .profile--user--info {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}
.profile--user--info--visual {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .profile--user--info--visual {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}
.profile--user--info--visual img {
  width: 18rem;
  height: 18rem;
  border: solid transparent;
  border-radius: 100%;
}
.profile--user--info--visual--edit_button {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 18rem;
  padding: 1.2rem;
  background: #003169;
  border: 2px solid #191919;
  color: #ffffff;
  font-weight: bold;
  letter-spacing: 3px;
  text-transform: uppercase;
  transition: 0.3s;
}
.profile--user--info--visual--edit_button:hover {
  background: #0464c9;
  transform: translateY(-0.1rem);
}
.profile--user--info--visual--edit_button a {
  text-decoration: none;
  color: #ffffff !important;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}
.profile--user--info--texts {
  margin-top: 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media screen and (max-width: 640px) {
  .profile--user--info--texts {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
}
.profile--user--info--texts--immutable {
  color: #868686;
}
.profile--user--info--texts--tags {
  margin: 1.6rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 5rem;
}
@media screen and (max-width: 640px) {
  .profile--user--info--texts--tags {
    justify-content: center;
  }
}
.profile--user--info--texts--tags--discord {
  background-color: #7289da;
  color: #ffffff;
  padding: 0.5rem 2rem;
  border-radius: 100px;
}
.profile--user--info--texts--tags--game {
  background-color: #4a6f28;
  color: #ffffff;
  padding: 0.5rem 2rem;
  border-radius: 100px;
}