.edit_wrapper {
  margin: 0 10vw;
  padding: 10rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 10rem;
}
@media screen and (max-width: 1024px) {
  .edit_wrapper {
    margin: 0;
    padding: 10rem 2rem;
  }
}