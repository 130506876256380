.forgot_password {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  align-items: stretch;
  box-sizing: border-box;
  border-radius: 0.6rem;
  padding: 5rem;
  width: 60rem;
  background: #ffffff;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 640px) {
  .forgot_password {
    height: 100%;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
  }
}
.forgot_password--title {
  text-align: center;
  text-transform: uppercase;
}
.forgot_password--form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  align-items: stretch;
}
.forgot_password--options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  text-transform: none;
}
@media screen and (max-width: 640px) {
  .forgot_password--options {
    text-align: center;
  }
}
.forgot_password--content {
  color: #000;
}